import {
    IonApp,
    IonLabel,
    IonPage,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    setupIonicReact,
} from "@ionic/react";
import React, { Component } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { IonReactRouter } from "@ionic/react-router";
import LoginPage from "./pages/Login/LoginPage";
import Viio from "./pages/Viio/Viio";
import ActivityPage from "./pages/Activity/ActivityPage";
import CardPage from "./pages/Card/CardPage";
import ConfigPage from "./pages/Config/ConfigPage";
import symb from "./assets/images/LOGOSYMB.svg";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
// import './theme/variables.css';
import "./styles/setup.scss";
import "./theme/Dashboard.scss";
import Header from "./components/Header/Header";
import Balance from "./components/Transfer/Balance/Balance";
import ChargeDiscardButton from "./components/Buttons/ChargeDiscardButton/ChargeDiscardButton";
import { useEffect, useState } from "react";
import ChargePage from "./pages/Charge/ChargePage";
import WithdrawPage from "./pages/Withdraw/WithdrawPage";
import SecurityPage from "./pages/Security/SecurityPage";
import RegisterPage from "./pages/Register/RegisterPage";
import ResponsePage from "./pages/Response/ResponsePage";
import UserPage from "./pages/User/User";

import ProtectedRoute from "./pages/ProtectedRoute/ProtectedRoute";
import RecoverPasswordPage from "./pages/RevocerPassword/RecoverPasswordPage";
import ResetPasswordPage from "./pages/ResetPassword/ResetPasswordPage";
import TutorialPage from "./pages/Tutorial/TutorialPage";
import Modal from "./components/Modal/Modal";

import AppUrlListener from "./AppUrlListener";

// redux
import { useSelector } from "react-redux";
import WalletPage from "./pages/Wallet/WalletPage";
import TransferPage from "./pages/Transfer/TransferPage";
import TransferOptions from "./components/Transfer/TransferOptions/TransferOptions";

setupIonicReact();

const App: React.FC = () => {

    const modal = useSelector((state: any) => state.modal);

    return (
        
        <IonApp>
            <Modal 
                show={modal.show} 
                title={modal.title} 
                label={modal.label} 
                onClick={modal.onClick} 
                content={modal.content} 
                secondaryContent={modal.secondaryContent}
                icon={modal.icon} 
                altButtonLabel={modal.altButtonLabel}
                onAltClick={modal.onAltClick}
                hideButtons={modal.hideButtons}
            />
            <IonReactRouter basename={process.env.REACT_APP_BASE_URL}>
                <AppUrlListener></AppUrlListener>
                <IonRouterOutlet>
                    <Route path="/login" component={LoginPage} />
                    <Route path="/recover" component={RecoverPasswordPage} />
                    <Route path="/reset" component={ResetPasswordPage} />

                    <Route
                        path="/dashboard"
                        render={() => {
                            return (
                                <ProtectedRoute>
                                    <DashboardRouterOutlet />
                                </ProtectedRoute>
                            );
                        }}
                    />
                    <Route
                        path="/charge"
                        render={() => {
                            return (
                                <ProtectedRoute>
                                    <ChargePage />
                                </ProtectedRoute>
                            );
                        }}
                    />
                    <Route
                        path="/withdraw"
                        render={() => {
                            return (
                                <ProtectedRoute>
                                    <WithdrawPage />
                                </ProtectedRoute>
                            );
                        }}
                    />
                    <Route
                        path="/transfer"
                        render={() => {
                            return (
                                <ProtectedRoute>
                                    <TransferPage />
                                </ProtectedRoute>
                            );
                        }}
                    />
                    <Route
                        path="/security"
                        render={() => {
                            return (
                                <ProtectedRoute>
                                    <SecurityPage />
                                </ProtectedRoute>
                            );
                        }}
                    />
                    <Route
                        path="/wallet"
                        render={() => {
                            return (
                                <ProtectedRoute>
                                    <WalletPage />
                                </ProtectedRoute>
                            );
                        }}
                    />
                    <Route path="/register" component={RegisterPage} />
                    <Route
                        path="/response"
                        render={() => {
                            return (
                                <ProtectedRoute>
                                    <ResponsePage />
                                </ProtectedRoute>
                            );
                        }}
                    />
                    <Route
                        path="/user"
                        render={() => {
                            return (
                                <ProtectedRoute>
                                    <UserPage />
                                </ProtectedRoute>
                            );
                        }}
                    />
                    <Route
                        path="/tutorial"
                        render={() => {
                            return (
                                <ProtectedRoute>
                                    <TutorialPage />
                                </ProtectedRoute>
                            );
                        }}
                    />
                    <Route 
                        render={() => {
                            return (<Redirect to="/login" /> );
                    }}/>
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    );
};

const DashboardRouterOutlet: React.FC = () => {
    const history = useHistory();

    const location = useLocation();
    const [onViio, setOnViio] = useState<boolean>(false);
    const [onLogin, setOnLogin] = useState<boolean>(false);

    const [openTransferOptions, setOpenTransferOptions] = useState<boolean>(false)

    useEffect(() => {
        if (location.pathname.includes("login")) setOnLogin(true);
        else setOnLogin(false);
        if (location.pathname.includes("dashboard/viio")) setOnViio(true);
        else setOnViio(false);
    }, [location]);

    const goToChargePage = () => {
        history.push("/charge");
    };

    const goToWithdrawPage = () => {
        history.push("/withdraw");
    };

    const handleRedirect = (route: string) => {
        setOpenTransferOptions(false);
        history.push(route);
    }

    return (
        <>
            <IonTabs>
                <IonRouterOutlet>
                    <Route path="/dashboard/viio" component={Viio} exact={true} />
                    <Route path="/dashboard/activity" component={ActivityPage} exact={true} />
                    <Route path="/dashboard/card" component={CardPage} exact={true} />
                    <Route path="/dashboard/config" component={ConfigPage} exact={true} />
                </IonRouterOutlet>
                <IonTabBar slot="bottom" className="menu">
                    <IonTabButton tab="viio" href="/dashboard/viio">
                        <div className="dashboard__icon">A</div>
                        <IonLabel>VIIO</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="activity" href="/dashboard/activity">
                        <div className="dashboard__icon">B</div>
                        <IonLabel>Actividad</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="item" onClick={() => setOpenTransferOptions(true)}>
                        <div className="dashboard__viio-btn">
                            <img src={symb} alt="Menu" width="33"></img>
                        </div>
                    </IonTabButton>
                    <IonTabButton tab="card" href="/dashboard/card">
                        <div className="dashboard__icon">C</div>
                        <IonLabel>Tarjeta</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="configuration" href="/dashboard/config">
                        <div className="dashboard__icon">D</div>
                        <IonLabel>Config.</IonLabel>
                    </IonTabButton>
                </IonTabBar>
            </IonTabs>
            <Header viio={onViio} onLogin={onLogin} />
            {onViio && (
                <>
                    <Balance />
                    <ChargeDiscardButton
                        handleChargeButton={goToChargePage}
                        handleWithdrawButton={goToWithdrawPage}
                    />
                </>
            )}
             <TransferOptions 
                isOpen={openTransferOptions} 
                onClose={() => setOpenTransferOptions(false)} 
                onRedirect={handleRedirect}
            />
        </>
    );
};

export default App;
